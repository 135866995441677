import { Component } from 'react';

import Main from "./components/Main"
import './App.css';


class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      hide: false,

    }

  }

  componentDidMount() {
    console.log(Notification.permission);
    if(Notification.permission!=="denied") {
      Notification.requestPermission().then(permission=>{
        console.log(permission)
      })
    }
  }

  toggle() {
    this.setState({ hide: false })
  }

  render() {
    return (
      <div>
        {!this.state.hide && Main != null && <Main />}

        {this.state.hide && <div style={{overflow:"hidden",height:"100vh"}}><img alt=""  onClick={() => this.toggle()} width={2000} src="https://cdn.discordapp.com/attachments/512285745415979031/895755761454579742/pigeon.png" ></img></div>}
      </div>
    );
  }
}

export default App;
