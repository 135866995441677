import { Component } from "react";

import Autocomplete from "./AutocompleteRelics";

import axios from 'axios';

const URL_RELICS = "https://drops.warframestat.us/data/relics/"
const URL_API = "/api"

class Relics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            relics: {},
            relic_type: "lith",
            team_relics: ["", "", "", ""],
            suggestions: [],
            relicDrops: [],
            toGetList: [],
        }
    }


    getRelicDrops(relic) {
        //console.log(relic)
        let oldRelicDrop = this.state.relicDrops;
        oldRelicDrop = oldRelicDrop.filter(drop => drop.index !== relic.index)
        console.log("oldRelicDrop", oldRelicDrop)
        this.setState({ relicDrops: oldRelicDrop })
        if (!this.state.suggestions.includes(relic.name)) return;
        let relic_type_cap = relic.type.charAt(0).toUpperCase() + relic.type.slice(1)
        axios.get(URL_RELICS + "/" + relic_type_cap + "/" + relic.name + ".json")
            .then(res => {
                console.log(res.data)
                res.data.rewards.Intact.forEach(drop => {
                    var found = false;
                    for (var i = 0; i < oldRelicDrop.length; i++) {
                        console.log("verif", oldRelicDrop[i].item === drop.itemName)
                        if (oldRelicDrop[i].item === drop.itemName) {
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        if (drop.itemName.includes("Forma")) return
                        let items = this.props.items
                        items.some(item => {
                            if (drop.itemName.includes(item.item_name)) {
                                let oldtoGet = this.state.toGetList;
                                oldtoGet.push({ url: URL_API + "/items/" + item.url_name + "/orders", item: drop.itemName })
                                this.setState({ toGetList: oldtoGet })
                                return null;
                            }
                            return null;
                        })
                        //let req = URL_API + "/items/" + order.item.url_name + "/orders"
                        //console.log(req)
                        oldRelicDrop.push({ item: drop.itemName, prices: "", index: relic.index })
                        //console.log("toGet", this.state.toGetList)


                    }
                })
                oldRelicDrop.sort((a, b) => (a.item > b.item) ? 1 : -1);
                //console.log(oldRelicDrop)
                this.setState({ relicDrops: oldRelicDrop })
                this.fetchRelicDropPrices();
            })
            .catch(err => {
                //console.log(err)
            })

    }

    fetchRelicDropPrices() {
        let getList = this.state.toGetList;
        if (Number(getList.length) === 0) return;
        //var dropfetch = { ...getList[0] };
        getList.forEach(dropfetch => {
            axios.get(dropfetch.url)
                .then(res => {
                    let orders = res.data.payload.orders;
                    orders = orders.filter((o) => (o.user.status !== "offline" && o.order_type === "sell"))
                    orders.sort((a, b) => (a.platinum > b.platinum) ? 1 : -1)
                    //console.log("addOrder", order, orders)
                    let prices = (orders[0] != null ? orders[0].platinum : "") + " " + (orders[1] != null ? orders[1].platinum : "") + " " + (orders[2] != null ? orders[2].platinum : "") + " " + (orders[3] != null ? orders[3].platinum : "") + " " + (orders[4] != null ? orders[4].platinum : "")
                    let oldReliceDrop = this.state.relicDrops;
                    oldReliceDrop = oldReliceDrop.map(drop => {
                        if (drop.item === dropfetch.item) return { ...drop, prices }
                        else return { ...drop }
                    })
                    getList.shift()
                    this.setState({ relicDrops: oldReliceDrop, toGetList: getList })

                })
        })


    }


    relicTypeChange = (e) => {
        //console.log(e.target.value)
        var name_list = this.state.relics[e.target.value].map(relic => {
            return relic.name
        })
        name_list.sort()
        //console.log(name_list)
        this.setState({ relicDrops: [], relic_type: e.target.value, suggestions: name_list })
    }

    setRelic = (value, index) => {
        let newTeamRelic = this.state.team_relics;
        newTeamRelic[index] = value
        this.getRelicDrops({ index, name: value, type: this.state.relic_type })
        this.setState({ team_relics: newTeamRelic })
        //if (this.state.team_relics[0] === "") console.log(true)
        //console.log(value, index);
    }

    getRelics() {
        axios.get(URL_API + "/items")
            .then(response => {
                // handle success

                let item_list = response.data.payload.items
                item_list = item_list.filter((item) => {
                    return item.url_name.includes("_relic")
                })
                var meso = []
                var lith = []
                var axi = []
                var requiem = []
                var neo = []
                item_list.forEach(element => {
                    var relic = { url_name: element.url_name, name: element.item_name.split(' ')[1] }
                    switch (element.item_name.split(' ')[0]) {
                        case "Meso":
                            meso.push(relic)
                            break;
                        case "Requiem":
                            requiem.push(relic)
                            break;
                        case "Lith":
                            lith.push(relic)
                            break;
                        case "Neo":
                            neo.push(relic)
                            break;
                        case "Axi":
                            axi.push(relic)
                            break;
                        default:
                            break;
                    }
                });
                var relics = { meso, lith, axi, requiem, neo }
                var name_list = relics[this.state.relic_type].map(relic => {
                    return relic.name
                })
                name_list.sort()
                this.setState({ relics: relics, suggestions: name_list })
                //console.log("relics", relics)
            })
            .catch(function (error) {
                // handle error
                //console.log(error);
            })
            .then(function () {
                // always executed
            })

    }

    componentDidMount() {
        this.getRelics();
    }


    render() {
        //console.log(this.state.relicDrops.length !== 0)
        return (
            <div className="card border-dark relics">
                <div className="card-header">
                    <strong>Relics</strong>
                </div>
                <div className="card-body">
                    <div className="form-group col-5">
                        <select onChange={this.relicTypeChange} value={this.state.relic_type} className="form-control ">
                            <option value="lith" >Lith</option>
                            <option value="meso" >Meso</option>
                            <option value="neo" >Neo</option>
                            <option value="axi" >Axi</option>
                            <option value="requiem" >Requiem</option>
                        </select>
                    </div>
                    <div className="d-flex ">
                        <div className="form-group d-flex mx-3">
                            <Autocomplete setRelic={this.setRelic} index={0} suggestions={this.state.suggestions} />
                            <Autocomplete setRelic={this.setRelic} index={1} suggestions={this.state.suggestions} />
                            <Autocomplete setRelic={this.setRelic} index={2} suggestions={this.state.suggestions} />
                            <Autocomplete setRelic={this.setRelic} index={3} suggestions={this.state.suggestions} />
                        </div>
                    </div>
                    {this.state.relicDrops.length !== 0 && <div className="card border-dark mx-3">
                        <table className="col-12">
                            <tbody>
                                {
                                    this.state.relicDrops.map(drop => {
                                        //console.log(drop)
                                        return <tr key={drop.item}>
                                            <td>{drop.item}</td>
                                            <td>{drop.prices}</td>
                                        </tr>
                                    })

                                }

                            </tbody>
                        </table>
                    </div>}
                </div>
            </div>
        )
    }
}

export default Relics;