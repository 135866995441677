import { Component } from "react";
import axios from "axios";

const URL_API = "/api";

class Trades extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newTradeItemName: "",
            newTradeItemPrice: "",
            stonks: [],
            lastTimeRequestNumber: 0,
            currentRequstNumber: 0,
            updating: false,
        }

        this.stonks = {}
        this.stonksPrices = {}

    }

    nameChange = (value) => {
        this.setState({ newTradeItemName: value })
    }

    priceChange = (e) => {
        this.setState({ newTradeItemPrice: e.target.value })
    }

    handleClick = () => {
        const { newTradeItemName, newTradeItemPrice } = this.state;
        console.log(newTradeItemName, newTradeItemPrice)
        let newTrades = this.props.trades;
        newTrades.key = Math.random();
        newTrades.push({ name: newTradeItemName, platinum: newTradeItemPrice, key: Math.random() })
        this.props.updateTrades(newTrades);
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    delay = (set) => {
        return axios.get(URL_API + "/item/" + set.slug +"/set")
            .then(response => {
                return response;
            })
    }



    doNextPromise = (dbs) => {
        console.log("doNextPromise", dbs)
        if (dbs.length !== 0) {
            let currentRequstNumber = this.state.currentRequstNumber;
            currentRequstNumber++;
            this.setState({ currentRequstNumber })
            const set = dbs.shift();
            this.delay(set).then(x => {
                console.log(x.data.data.items);
                if(x.data.data.items.length > 1){
                    this.stonks[set.slug] = { items_in_set: x.data.data.items, ...set };
                }
                this.sleep(100).then(() => {
                    this.doNextPromise(dbs);
                });

            })
        } else {
            this.retrivesPrices()
        }
    }

    retrivesPrices() {
        const { currentRequstNumber } = this.state;
        let items = []
        for (const value of Object.values(this.stonks)) {
            items = items.concat(value.items_in_set)
        }

        this.setState({ lastTimeRequestNumber: (currentRequstNumber + items.length) })

        this.getNextPrice(items);
    }


    getNextPrice = (items) => {
        if (items.length !== 0) {
            let currentRequstNumber = this.state.currentRequstNumber;
            currentRequstNumber++;
            this.setState({ currentRequstNumber })
            const item = items.shift();
            this.getPrice(item).then(x => {
                console.log("getPrice",x.data.data.sell)
                this.stonksPrices[item.slug] = x.data.data.sell;
                this.sleep(100).then(() => {
                    this.getNextPrice(items);
                });

            })
        } else {
            this.processItemPrices()
        }
    }

    getPrice = (item) => {
        return axios.get(URL_API + "/orders/item/" + item.slug + "/top")
            .then(response => {
                return response;
            })
    }

    processItemPrices() {
        for (const [key, value] of Object.entries(this.stonks)) {
            console.log(key,value)
            let somme = 0;
            value.orders = this.stonksPrices[key];
            console.log(key,value)
            value.items_in_set = value.items_in_set.filter(item => item.url_name !== key);
            value.items_in_set = value.items_in_set.map(item => {
                item.orders = this.stonksPrices[item.url_name];
                somme = somme + (item.orders[0].platinum * item.quantity_for_set)
                return item
            })
            value.total = somme;
            value.dif = value.orders[0].platinum - somme
        }
        localStorage.setItem("lastTimeRequestNumber", Object.keys(this.stonks).length + Object.keys(this.stonksPrices).length);

        let stonks = Object.values(this.stonks);
        stonks.sort((setA, setB) => setB.dif - setA.dif)
        localStorage.setItem("lastStonks", JSON.stringify(stonks))
        this.setState({ stonks, updating: false })


    }

    updateSetAnalyse = () => {
        this.setState({ currentRequstNumber: 0, updating: true })
        axios.get(URL_API + "/items")
            .then(response => {
                console.log(response.data.data)
                let bds = response.data.data.filter(item => item.i18n.en.name.includes("Prime Set"));
                console.log(bds)
                bds = bds.slice(1, 5);


                this.doNextPromise(bds.slice());
            })
            .catch(err => {
                console.log(err)
            })
    }

    componentDidMount = () => {
        let lastTimeRequestNumber = localStorage.getItem("lastTimeRequestNumber")
        if (lastTimeRequestNumber) {
            console.log("lastTimeRequestNumber", lastTimeRequestNumber)
            this.setState({ lastTimeRequestNumber: Number(lastTimeRequestNumber) })
        }
        let lastStonks
        try{
            lastStonks = JSON.parse(localStorage.getItem("lastStonks"));
        } catch{
            localStorage.removeItem("lastStonks")
        }
        
        if (lastStonks) {
            console.log("lastStonks", lastStonks)
            this.setState({ stonks: lastStonks })
        } else {
            this.updateSetAnalyse();
        }


    }

    render() {
        const { stonks, lastTimeRequestNumber, currentRequstNumber, updating } = this.state;
        let currentProgress = 100 * currentRequstNumber / lastTimeRequestNumber;
        currentProgress = currentProgress.toFixed(2)
        if (currentProgress > 100) currentProgress = 100

        return (
            <div className="card border-dark trades mt-4" style={{height:"600px"}}>
                <div className="d-flex justify-content-between">
                    <h5 className="m-2">
                        <strong>Stonks</strong>
                    </h5>
                    {updating ?
                        <div class="progress w-100 m-2 mt-3">
                            <div className="progress-bar" role="progressbar" style={{ width: currentProgress + "%" }} aria-valuenow={currentProgress} aria-valuemin="0" aria-valuemax="100">{currentProgress}%</div>
                        </div> :
                        <button style={{ boxShadow: "none", outline: "none" }} className="btn-sm btn-secondary m-1" onClick={this.updateSetAnalyse} >Actualiser</button>
                    }
                </div>
                <div className="card-body m-0 p-2" style={{overflow: "auto"}}>
                    <div className="">
                        {
                            stonks.length !== 0 &&
                            <div className=""  >
                                {
                                    stonks.map(set => {
                                        const orders = set.orders
                                        let prices = (orders[0] != null ? orders[0].platinum : "") + " " + (orders[1] != null ? orders[1].platinum : "") + " " + (orders[2] != null ? orders[2].platinum : "") + " " + (orders[3] != null ? orders[3].platinum : "") + " " + (orders[4] != null ? orders[4].platinum : "")
                                        return <div className="border-dark mb-2 d-flex flex-row">
                                            <div className="m-1 col-3">
                                                <div className="m-1">
                                                    <a style={{ maxWidth: "180px", color: "#5f66d9",textDecoration:"none" }} rel="noopener noreferrer" target='_blank' href={"https://warframe.market/items/" + set.url_name}>{set.item_name}</a>
                                                </div>
                                                <div className="m-1">{prices}</div>
                                                <div className="m-1">Total : {set.total} Dif : {set.dif}</div>
                                            </div>
                                            <div className="">
                                                {
                                                    set.items_in_set.map(item => {
                                                        let itemName = item.en.item_name.replace(set.item_name.replace(" Set", ''), '').split(' ')[1];
                                                        if (item.quantity_for_set !== 1) {
                                                            itemName = item.quantity_for_set + "x " + itemName
                                                        }
                                                        const orders = item.orders
                                                        let prices = (orders[0] != null ? orders[0].platinum : "") + " " + (orders[1] != null ? orders[1].platinum : "") + " " + (orders[2] != null ? orders[2].platinum : "") + " " + (orders[3] != null ? orders[3].platinum : "") + " " + (orders[4] != null ? orders[4].platinum : "")
                                                        return <div className="m-1 d-flex">

                                                            <a className="mr-2" style={{ maxWidth: "180px", color: "#5f66d9",textDecoration:"none" }} rel="noopener noreferrer" target='_blank' href={"https://warframe.market/items/" + item.url_name}>{itemName} </a>
                                                            <div>{prices}</div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>



                            /*
                                trades.length !== 0 && <div key={trades.key} className="card card-custom mb-1" style={{ backgroundColor: "rgb(52, 58, 64)" }}>
                                    <table className="">
                                        <tbody>
                                            {
                                                trades.map(trade => {
                                                    return <tr key={trade.key} className="" >
    
                                                        <td className="pr-0" style={{maxWidth:"200px"}} >{trade.name}</td>
                                                        <td className="pl-1">{trade.platinum}</td>
                                                        <td className="">200 200 200 200 200</td>
    
                                                        <td style={{maxWidth:"50px"}} className="form-group  mr-3 m-0 p-0">
                                                            <input type="text" className=" p-1 form-control" />
                                                        </td>
                                                        <td className="p-0">
                                                            <button style={{ boxShadow: "none", outline: "none" }} className="custom-button btn btn-secondary ml-1">Sold</button>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>*/
                        }

                    </div>
                </div>
            </div>
        )
    }
}


export default Trades;