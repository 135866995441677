import { Component } from "react";

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { disableSound, hideSales, hideTrades, hideRelics, price_difference, display_number, primeOnly } = this.props.settings;
        const settings = this.props.settings;
        return (
            <div className="d-flex">
                <div id="setting1" className="input-group m-2 " style={{ width: "220px" }}>
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">Display Number</span>
                    </div>
                    <input id="display_number" onChange={(e) => this.props.updateSettings({ ...settings, display_number: e.target.value })} value={display_number} min="1" max="100" type="number" className="form-control" />
                </div>
                <div id="setting2" className="input-group m-2" style={{ width: "220px" }}>
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">Price difference</span>
                    </div>
                    <input id="price_difference" onChange={(e) => this.props.updateSettings({ ...settings, price_difference: e.target.value })} value={price_difference} min="0" max="100" type="number" className="form-control" />
                </div>
                <div className="form-check m-2 mt-3">
                    <input checked={!hideSales} onChange={() => this.props.updateSettings({ ...settings, hideSales: !hideSales })} className="form-check-input" type="checkbox" id="sales" style={{ width: "18px", height: "18px", }}></input>
                    <label className="form-check-label ml-2 h5" htmlFor="sales"><strong>Sales</strong>

                    </label>
                </div>
                <div className="form-check  m-2 mt-3">
                    <input checked={!hideTrades} onChange={() => this.props.updateSettings({ ...settings, hideTrades: !hideTrades })} className="form-check-input" type="checkbox" id="trades" style={{ width: "18px", height: "18px", }}></input>
                    <label className="form-check-label ml-2 h5" htmlFor="trades">
                        <strong>Stonks</strong>
                    </label>
                </div>
                <div className="form-check  m-2 mt-3">
                    <input checked={!hideRelics} onChange={() => this.props.updateSettings({ ...settings, hideRelics: !hideRelics })} className="form-check-input" type="checkbox" id="relics" style={{ width: "18px", height: "18px", }}></input>
                    <label className="form-check-label ml-2 h5" htmlFor="relics">
                        <strong>Relics</strong>
                    </label>
                </div>
                <div className="form-check  m-2 mt-3">
                    <input checked={disableSound} onChange={() => this.props.updateSettings({ ...settings, disableSound: !disableSound })} className="form-check-input" type="checkbox" id="disableSound" style={{ width: "18px", height: "18px", }}></input>
                    <label className="form-check-label ml-2 h5" htmlFor="disableSound">
                        <strong>Sound</strong>
                    </label>
                </div>
                <div className="form-check  m-2 mt-3">
                    <input checked={primeOnly} onChange={() => this.props.updateSettings({ ...settings, primeOnly: !primeOnly })} className="form-check-input" type="checkbox" id="primeOnly" style={{ width: "18px", height: "18px", }}></input>
                    <label className="form-check-label ml-2 h5" htmlFor="primeOnly">
                        <strong>Prime Only</strong>
                    </label>
                </div>
            </div>
        )
    }
}

export default Settings;