import { Component } from "react";

import sound from "../assets/bananana.mp3";

import axios from 'axios';

const URL_SOCKET = "wss://warframe.market/socket?platform=pc"
const TYPE_COUNT = "@WS/MESSAGE/ONLINE_COUNT"
const URL_API = "/api"
//const TYPE_NEW_ORDER = "@WS/SUBSCRIPTIONS/MOST_RECENT/NEW_ORDER"
const MARKET_PHRASE = "/w %user% Hi! I want to buy: %item_name% for %price% platinum. (warframe.market)"

class Sales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayed_orders: [],
            saved_order: [],
            connected: false,
        }

        this.webSocket = null;
    }


    removeSaved = (id) => {
        let saved = this.state.saved_order;
        saved = saved.filter(item => {
            //console.log(item)
            //console.log("item.id", item.id)
            //console.log("id", id)
            return item.id !== id
        })
        //console.log(saved)
        this.setState({ saved_order: saved })
    }


    handleCopy = (e, order) => {
        console.log(e.clientX, e.clientY)
        let phrase = MARKET_PHRASE;
        phrase = phrase.replace("%user%", order.user)
        phrase = phrase.replace("%item_name%", order.en_name)
        phrase = phrase.replace("%price%", order.platinum)
        var elem = document.createElement("input")
        elem.value = phrase
        e.target.appendChild(elem)
        elem.select()
        document.execCommand('copy')
        e.target.removeChild(elem)
        console.log(phrase)
    }


    begin() {
        if (this.props.settings.hideSales) return;
        console.log("Connexion Started")
        this.webSocket = new WebSocket(URL_SOCKET);

        this.webSocket.onclose = () => {
            console.log("Connexion Closed")
            this.setState({ connected: false })
        }
        this.webSocket.onopen = () => {
            this.webSocket.send('{"type": "@WS/SUBSCRIBE/MOST_RECENT"}')
            this.setState({ connected: true })
        };
        this.webSocket.onmessage = (event) => {

            let packet = JSON.parse(event.data);
            //console.log(event.data)

            if (packet.type === TYPE_COUNT) return
            if (packet.payload.order == null) {
                console.log(packet)
                return
            }
            let order = packet.payload.order
            const itemName = order.item.en.item_name;
            if (this.props.settings.primeOnly && !itemName.includes("Prime ")) {
                console.log("not a prime " + itemName)
                return
            }
            if (order.order_type === "buy") return
            //console.log(order.item.fr.item_name, order.platinum, order.item.url_name)
            //return
            //console.log("order",order);
            axios.get(URL_API + "/orders/item/" + order.item.url_name + "/top")
                .then(response => {
                    // handle success
                    //console.log("item resp",response)
                    let orders = response.data.payload.orders
                    orders = orders.filter((o) => (o.user.status !== "offline"))
                    orders.sort((a, b) => (a.platinum > b.platinum) ? 1 : -1)
                    //console.log("addOrder", order, orders)
                    let others = (orders[0] != null ? orders[0].platinum : "") + " " + (orders[1] != null ? orders[1].platinum : "") + " " + (orders[2] != null ? orders[2].platinum : "") + " " + (orders[3] != null ? orders[3].platinum : "") + " " + (orders[4] != null ? orders[4].platinum : "")
                    //console.log("Plop 1")
                    let one_order = {
                        en_name: order.item.en.item_name,
                        item: order.item.fr.item_name,
                        url: order.item.url_name,
                        user: order.user.ingame_name,
                        platinum: order.platinum,
                        prices: others,
                        id: Math.random(),

                    }
                    if ((Number(order.platinum) + Number(this.props.settings.price_difference)) < orders[1].platinum && orders[0].platinum !== 1) {
                        //console.log("Good",order.item.fr.item_name + " for " + order.item.platinum + " instead of : " + others)
                        one_order.color = "less"
                        console.log(order.item.fr.item_name + " for " + order.platinum + " instead of : " + others);
                        //console.log("will play sound")
                        if (this.props.settings.disableSound) {
                            //console.log("sound played")
                            let audio = new Audio(sound);
                            audio.play();
                            const notif = new Notification(order.item.fr.item_name + " for " + order.platinum + " instead of : " + others)
                        }


                    } else {
                        one_order.color = "nope"
                    }
                    //console.log(one_order)
                    let display_number = this.props.settings.display_number
                    let newdisplay = this.state.displayed_orders.slice(0, display_number - 1);
                    let deldisplay = this.state.displayed_orders.slice(display_number - 1)

                    newdisplay.unshift(one_order);
                    //console.log("display", newdisplay, deldisplay)
                    deldisplay.forEach(order => {
                        if (order.color === "less") {
                            let newsaved = this.state.saved_order;
                            newsaved.push(order);
                            this.setState({ saved_order: newsaved })
                        }
                    })
                    this.setState({ displayed_orders: newdisplay })

                    //addOrder(order, orders);
                    //console.log("Plop 5")

                })
                .catch(function (err) {
                    // handle error
                    console.log(err);
                })
                .then(function () {
                    // always executed
                })
        }
    }

    componentDidMount() {
        window.onbeforeunload = function () {
            localStorage.setItem("displayed_orders", JSON.stringify(this.state.displayed_orders))
            localStorage.setItem("saved_order", JSON.stringify(this.state.saved_order))
        }.bind(this);
        let initialstate = {}
        if (localStorage.getItem("displayed_orders") !== null) {
            initialstate.displayed_orders = JSON.parse(localStorage.getItem("displayed_orders"))
        }
        if (localStorage.getItem("saved_order") !== null) {
            initialstate.saved_order = JSON.parse(localStorage.getItem("saved_order"))
        }
        //console.log("initialstate", initialstate)
        this.setState({ ...initialstate })

        this.begin();
    }

    componentWillUnmount() {
        localStorage.setItem("displayed_orders", JSON.stringify(this.state.displayed_orders))
        localStorage.setItem("saved_order", JSON.stringify(this.state.saved_order))

        this.webSocket.close()
    }

    toggleWS = () => {
        this.state.connected ? this.webSocket.close() : this.begin()
    }

    render() {
        const { connected } = this.state
        return (<div className="col-6">

            <div className="main-card card border-dark w-100" style={{ maxWidth: "100vw" }}>
                
                <table className="border-dark table mb-0 w-100">
                    <thead className="w-100">
                        <tr>
                            <th >Item name</th>
                            <th>Player</th>
                            <th style={{ width: "40px" }}></th>
                            <th style={{ width: "80px" }}>Price</th>
                            <th>Prices</th>
                        </tr>
                        <div className={`status ${connected ? "status-open" : "status-close" }`} onClick={this.toggleWS}></div>
                    </thead>
                    <tbody >
                        {
                            this.state.displayed_orders.map((order, i) => {
                                return <tr key={order.id} className={order.color}>

                                    <td>
                                        <a style={{ maxWidth: "180px" }} rel="noopener noreferrer" target='_blank' href={"https://warframe.market/items/" + order.url}>{order.item}</a>
                                    </td>
                                    <td>{order.user}</td>
                                    <td><i onMouseDown={(e) => this.handleCopy(e, order)} className="fa fa-copy params"></i></td>
                                    <td className='pl-3'>{order.platinum}</td>

                                    <td>{order.prices}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="card border-dark w-100 mt-3" style={{ maxWidth: "100vw" }}>
                <div className="d-flex justify-content-between">
                    <h5 className="m-2 "><strong>Saved</strong></h5>
                    <button style={{ boxShadow: "none", outline: "none" }} className="btn-sm btn-secondary m-1" onClick={() => this.setState({ saved_order: [] })} >Delete All</button>
                </div>
                <table className="border-dark table mb-0 w-100">
                    <tbody id="saved-table">
                        {
                            this.state.saved_order.map((order, i) => {
                                return <tr key={order.id} className={order.color}>
                                    <td>
                                        <a style={{ maxWidth: "180px" }} rel="noopener noreferrer" target='_blank' href={"https://warframe.market/items/" + order.url}>{order.item}</a>
                                    </td>
                                    <td>{order.user}
                                    </td>
                                    <td><i onMouseDown={(e) => this.handleCopy(e, order)} className="fa fa-copy params"></i></td>
                                    <td className='pl-3'>{order.platinum}</td><td>{order.prices}
                                    </td>
                                    <td>
                                        <i onClick={() => this.removeSaved(order.id)} className="params fa fa-times fa-lg"></i>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>

        </div>)
    }
}
export default Sales