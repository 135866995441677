import { Component } from 'react';



import axios from 'axios';

import Sales from "./Sales";
import Trades from "./Trades";
import Relics from "./Relics";
import Settings from "./Settings";


const URL_API = "/api"

class Main extends Component {

    constructor(props) {
        super(props)
        this.state = {
            settings: {
                hideSales: false,
                hideTrades: false,
                hideRelics: false,
                disableSound: false,
                display_number: 10,
                price_difference: 0,
                primeOnly: false,
            },
            items: [],
            itemsnames: [],
            setting: false,
            trades: [],
        }

        this.webSocket = null;
        this.delays = [1000, 2000, 5000, 3000, 500, 12000];
        this.startTime = Date.now();

        this.stonks ={}

    }

    getItems() {
        axios.get(URL_API + "/items")
            .then(response => {
            
                /**
                 * 
                 */
                //console.log(response.data.data.payload.items.sort((a, b) => a.item_name > b.item_name ? 1 : -1))
                let itemnames = response.data.payload.items.map(item => {
                    return item.item_name
                })
                //console.log(itemnames.sort())
                this.setState({ itemsnames: itemnames.sort(), items: response.data.payload.items })

            })
            .catch(err => {
                //console.log(err)
            })
    }

    updateTrades = (trades) => {
        console.log(trades)
        const items = this.state.items
        let newTrades = trades.map(trade => {
            if (trade.url_name != null) return trade
            let item = items.find(item => {
                return item.item_name === trade.name
            })
            return axios.get(URL_API + "/items/" + item.url_name + "/orders")
                .then(response => {
                    // handle success
                    //console.log("item resp",response.data)
                    let orders = response.data.payload.orders
                    orders = orders.filter((o) => (o.user.status !== "offline" && o.order_type === "sell"))
                    orders.sort((a, b) => (a.platinum > b.platinum) ? 1 : -1)
                    //console.log("addOrder", order, orders)
                    let others = (orders[0] != null ? orders[0].platinum : "") + " " + (orders[1] != null ? orders[1].platinum : "") + " " + (orders[2] != null ? orders[2].platinum : "") + " " + (orders[3] != null ? orders[3].platinum : "") + " " + (orders[4] != null ? orders[4].platinum : "")
                    //console.log("Plop 1")
                    return {
                        ...trade,
                        url_name: item.url_name,
                        prices: others,
                    }
                })

        })
        this.setState({ trades: newTrades });
    }



    toggleSetting = () => {
        //console.log(this.state.setting)
        this.setState({ setting: !this.state.setting })
    }

    updateSettings = (settings) => {
        localStorage.setItem("settings", JSON.stringify({ ...settings }))
        this.setState({ settings: settings })
    }
    componentDidMount() {
        let initialstate = {}
        if (localStorage.getItem("settings") !== null) {
            initialstate = JSON.parse(localStorage.getItem("settings"));
        }
        this.setState({ settings: initialstate })
        this.getItems();
    }



    render() {
        const { hideSales, hideTrades, hideRelics } = this.state.settings;
        const setting = this.state.setting
        const settings = this.state.settings;
        const trades = this.state.trades;
        return (
            <div >
                {/*<!-- params -->*/}

                <div id="debug"></div>
                <div className="d-flex pl-2">
                    <i id="params" onClick={this.toggleSetting} className="mb-1 params fa fa-cog fa-2x mt-1 ml-2"></i>
                    {setting && <Settings updateSettings={this.updateSettings} settings={settings} />}

                </div>
                {/*<!-- Page-->*/}
                <div className="d-flex">
                    {!hideSales && <Sales settings={settings} />}
                    <div className="col-6 d-flex flex-column">

                        {!hideRelics && <Relics items={this.state.items} />}
                        {!hideTrades && <Trades updateTrades={this.updateTrades} itemsnames={this.state.itemsnames} trades={trades} />}
                    </div>
                </div>


            </div>

        );
    }

}

export default Main;